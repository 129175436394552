import React from 'react';

function NavBar() {
    return (
        <div className="nav-bar">
            <div>
                <span>
                    Cloud.ly
                </span>
            </div>
        </div>
    )
}

export default NavBar;