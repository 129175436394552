import React, { useState, useRef, useEffect } from 'react'
import NavBar from './NavBar';
import Content from './Content';
import AddBtn from './AddBtn';
import PostingModal from './PostingModal';
import PanelModal from './PanelModal';

function MainPage() {
    const [postingModal, setPostingModal] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [panelModal, setPanelModal] = useState(false);
    const [selectedPanel, selectPanel] = useState(null);
    const modalRef = useRef();

    function togglePostingModal() {
        setPostingModal(!postingModal);
    }

    function togglePanelModal() {
        setPanelModal(!panelModal);
    }

    function openPanelModal() {
        setPanelModal(true);
    }

    useEffect(() => {
        console.log(selectedPanel);
    }, [selectedPanel])

    return (
        <div className="main-page">
            <div className="main-page-wrapper">
                <NavBar />
                <Content 
                    refetch={refetch}
                    setRefetch={setRefetch}
                    modalRef={modalRef}
                    togglePanelModal={openPanelModal}
                    selectPanel={selectPanel}
                />
                <AddBtn 
                    onClick={togglePostingModal}
                />
                {
                    postingModal && 
                    <PostingModal 
                        setRefetch={setRefetch}
                        onClose={togglePostingModal}
                    /> 
                }
                {   
                    panelModal && 
                    <PanelModal 
                        setRefetch={setRefetch}
                        onClose={togglePanelModal}
                        selectedPanel={selectedPanel}
                    />
                }
            </div>
        </div>
    )
}

export default MainPage;