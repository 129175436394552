import React, { useEffect, useState, useRef } from "react";
import Panel from "./Panel";
import LoadingPage from "./LoadingPage";

function Content({
    refetch,
    setRefetch,
    modalRef,
    togglePanelModal,
    selectPanel,
}) {
    const [loaded, setLoaded] = useState(false);
    const [panels, setPanels] = useState([]);
    const [accessorFn, setAccessorFn] = useState(
        () => (a, b) => new Date(b.date) - new Date(a.date)
    );

    function fetchContent() {
        fetch("https://serverless-api.lennonchoong.workers.dev/posts", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setPanels(sortData(data.data, accessorFn));
                setLoaded(true);
            })
            .catch((e) => console.log(e));
    }

    function sortData(data, accessorFn) {
        const sortedData = [...data];
        sortedData.sort(accessorFn);
        return sortedData;
    }

    function updateLikeCount(newLikeCount, uuid) {
        const panelsCopy = [...panels];
        panelsCopy.forEach((d) => {
            if (d.uuid === uuid) {
                d.likes = newLikeCount;
            }
        });
        setPanels(sortData(panelsCopy, accessorFn));
    }

    useEffect(() => {
        fetchContent();
    }, []);

    useEffect(() => {
        if (refetch) {
            setRefetch(false);
            fetchContent();
        }
    }, [refetch]);

    useEffect(() => {
        setPanels(sortData(panels, accessorFn));
    }, [accessorFn]);

    return (
        <div className="content-wrapper">
            <SortButton setAccessorFn={setAccessorFn} />
            <div className="panel-wrapper">
                {loaded ? (
                    panels.map((p) => (
                        <Panel
                            data={p}
                            modalRef={modalRef}
                            togglePanelModal={togglePanelModal}
                            selectPanel={selectPanel}
                            updateLikeCount={updateLikeCount}
                        />
                    ))
                ) : (
                    <LoadingPage />
                )}
            </div>
        </div>
    );
}

function SortButton({ setAccessorFn }) {
    const [active, setActive] = useState(false);
    const [dimensions, setDimensions] = useState([0, 0]);
    const btnRef = useRef();

    useEffect(() => {
        if (btnRef.current) {
            setDimensions([
                btnRef.current.getBoundingClientRect().width,
                btnRef.current.getBoundingClientRect().height +
                    btnRef.current.getBoundingClientRect().top +
                    15,
            ]);
        }
    }, [btnRef.current]);

    return (
        <div className="sort-by-wrapper">
            <div
                className="sort-by-btn"
                ref={btnRef}
                onClick={() => setActive(!active)}
            >
                <span>Sort By</span>
            </div>
            {active && (
                <div
                    className="sort-by-dropdown"
                    style={{ width: dimensions[0], top: dimensions[1] }}
                >
                    <div
                        className="dropdown-opt"
                        onClick={() =>
                            setAccessorFn(
                                () => (a, b) =>
                                    new Date(b.date) - new Date(a.date)
                            )
                        }
                    >
                        Date
                    </div>
                    <div
                        className="dropdown-opt"
                        onClick={() =>
                            setAccessorFn(
                                () => (a, b) =>
                                    parseInt(b.likes) - parseInt(a.likes)
                            )
                        }
                    >
                        Likes
                    </div>
                </div>
            )}
        </div>
    );
}

export default Content;
