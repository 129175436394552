import React, { useEffect, useState } from 'react';
import timeSince from './timeSince';

function PanelModal({ selectedPanel, onClose, setRefetch }) {
    const { title, media, date, content, username, uuid, likes } = selectedPanel;

    const [curLikes, setCurLikes] = useState(parseInt(likes));

    async function like() {
        await fetch("https://serverless-api.lennonchoong.workers.dev/like", {
            method: "POST",
            body: JSON.stringify({ uuid: uuid }),
        })
        setRefetch(true);
    }

    async function dislike() {
        await fetch("https://serverless-api.lennonchoong.workers.dev/dislike", {
            method: "POST",
            body: JSON.stringify({ uuid: uuid }),
        })
        setRefetch(true);
    }

    useEffect(() => {
        setCurLikes(parseInt(likes));
    }, [likes]);

    return (
        <div className="panel-modal">
            <div style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'}}>
                <div onClick={() => onClose()}>Close</div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div
                    style={{
                        opacity: 0.5,
                        fontSize: "0.85rem",
                        marginBottom: "0.1rem",
                        alignSelf: 'flex-start',
                    }}
                >
                    Posted by {username} {timeSince(new Date(date))} ago
                </div>
                <div
                    style={{
                        fontSize: "1.2rem",
                        alignSelf: 'flex-start',
                    }}
                >
                    {title}
                </div>
                <div className="large-container">
                    <img src={media} alt="" />
                </div>
                <p style={{alignSelf: 'flex-start'}}>{content}</p>
            </div>
            <div className="like-dislike-wrapper">
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <div
                        className="arrow"
                        onClick={() => {
                            setCurLikes(curLikes + 1);
                            like();
                        }}
                    >
                        <svg
                            width="1rem"
                            height="auto"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z"
                                fill="#f7df1e"
                            />
                        </svg>
                    </div>
                    <div
                        className="arrow"
                        onClick={() => {
                            setCurLikes(curLikes - 1);
                            dislike();
                        }}
                    >
                        <svg
                            width="1rem"
                            height="auto"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.41 0.590027L6 5.17003L10.59 0.590027L12 2.00003L6 8.00003L0 2.00003L1.41 0.590027Z"
                                fill="#f7df1e"
                            />
                        </svg>
                    </div>
                    <div style={{ color: "#f7df1e", width: "1rem" }}>
                        <span>{curLikes}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PanelModal;