import React from 'react';

function LoadingPage() {
    return (
        <div className="loading-page">
            <div className="nb-spinner"></div>
        </div>
    )
}

export default LoadingPage;