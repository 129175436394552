import React from 'react'

function AddBtn({ onClick }) {
    return (
        <div className='add-btn-wrapper'>
            <div className="add-btn" onClick={() => onClick()}>
                <span>Post</span>
            </div>
        </div>
    )
}

export default AddBtn;