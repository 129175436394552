import React, { useEffect, useRef, useState } from "react";

function PostingModal({ onClose, setRefetch}) {
    const fileInputRef = useRef();
    const [title, setTitle] = useState("");
    const [username, setUsername] = useState("");
    const [content, setContent] = useState("");
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const [media, setMedia] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [validFile, setValidFile] = useState(false);
    const [success, setSuccess] = useState(false);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    async function submitForm() {
        if (!validFile) {
            setErrorMsg("Empty or invalid file");
            return;
        }

        
        if ((/^\s+$/).test(title) || (/^\s+$/).test(username) || (/^\s+$/).test(content)) {
            setErrorMsg("Missing fields");
            return;
        }

        const base64File = await getBase64(media);

        fetch("https://serverless-api.lennonchoong.workers.dev/posts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    title : title,
                    username : username,
                    content : content,
                },
                media: base64File,
            }),
        })
        .then(res => res.json())
        .then(data => {
            if (data['success']) {
                setSuccess(true);
                setRefetch(true);
            } else {
                setErrorMsg("Error posting");
            }
        })
        .catch(e => console.log(e));
    }

    function dispatchEventToFileInput() {
        if (!fileInputRef.current) return;

        fileInputRef.current.click();
    }

    useEffect(() => {
        if (!media) return;
        const fileSizeMB = media.size / 1000000;

        if (fileSizeMB >= 20) {
            setErrorMsg("File exceeds 20MB");
            return;
        }

        if (!validImageTypes.includes(media["type"])) {
            setErrorMsg("Supported file formats are .png, .jpeg and .gif");
            return;
        }

        setValidFile(true);
        setErrorMsg("");
    }, [media]);

    return (
        <div className="posting-modal">
            <div className="close-wrapper">
                <div onClick={() => onClose()} className="close-btn">
                    Close
                </div>
            </div>
            <div>
                <div className="input-wrapper">
                    <label htmlFor="title">Title:</label>
                    <input type="text" name="title" onChange={(e) => setTitle(e.target.value)}/>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="username">Username:</label>
                    <input type="text" name="username" onChange={(e) => setUsername(e.target.value)}/>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="content">Content:</label>
                    <textarea type="text" name="content" onChange={(e) => setContent(e.target.value)}/>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="file">Media:</label>
                    <div
                        className="choose-file-btn"
                        onClick={() => dispatchEventToFileInput()}
                    >
                        Choose a file
                    </div>
                    <input
                        name="file"
                        onChange={(e) => setMedia(e.target.files[0])}
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                    />
                </div>
            </div>
            <div className="success-msg">{success ? "Successfully posted" : ""}</div>
            <div className="error-msg">{errorMsg}</div>
            <div className="submit-btn" onClick={() => submitForm()}>
                Submit
            </div>
        </div>
    );
}

export default PostingModal;
