import React, { useState, useEffect, createPortal } from "react";
import timeSince from "./timeSince";

function Panel({ data, togglePanelModal, selectPanel, updateLikeCount }) {
    const { title, media, date, content, username, uuid, likes } = data;

    const [curLikes, setCurLikes] = useState(parseInt(likes));

    function like() {
        fetch("https://serverless-api.lennonchoong.workers.dev/like", {
            method: "POST",
            body: JSON.stringify({ uuid: uuid }),
        }).catch((e) => console.log(e));
    }

    function dislike() {
        fetch("https://serverless-api.lennonchoong.workers.dev/dislike", {
            method: "POST",
            body: JSON.stringify({ uuid: uuid }),
        }).catch((e) => console.log(e));
    }

    useEffect(() => {
        setCurLikes(parseInt(likes));
    }, [likes]);

    useEffect(() => {
        updateLikeCount(curLikes, uuid);
    }, [curLikes])

    return (
        <div
            className="panel"
            onClick={() => {
                togglePanelModal();
                selectPanel(data);
            }}
        >
            <div
                style={{
                    opacity: 0.5,
                    fontSize: "0.7rem",
                    marginBottom: "0.1rem",
                }}
            >
                Posted by {username} {timeSince(new Date(date))} ago
            </div>
            <div className="panel-header">
                <div>{title}</div>
            </div>
            <div className="media-container">
                <img src={media} alt="" />
            </div>
            <p className="panel-content">{content}</p>
            <div className="like-dislike-wrapper">
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <div
                        className="arrow"
                        onClick={(e) => {
                            e.stopPropagation();
                            setCurLikes(curLikes + 1);
                            like();
                        }}
                    >
                        <svg
                            width="1rem"
                            height="auto"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z"
                                fill="#f7df1e"
                            />
                        </svg>
                    </div>
                    <div
                        className="arrow"
                        onClick={(e) => {
                            e.stopPropagation();
                            setCurLikes(curLikes - 1);
                            dislike();
                        }}
                    >
                        <svg
                            width="1rem"
                            height="auto"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.41 0.590027L6 5.17003L10.59 0.590027L12 2.00003L6 8.00003L0 2.00003L1.41 0.590027Z"
                                fill="#f7df1e"
                            />
                        </svg>
                    </div>
                    <div style={{ color: "#f7df1e", width: "1rem" }}>
                        <span>{curLikes}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Panel;
